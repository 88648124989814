import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { useEffect, useState } from 'react';
import Head  from 'next/head';

export default function App({ Component, pageProps }: AppProps) {
  const [showBody, setShowBody] = useState(false);
  useEffect(() => {
    computeRem()
    window.onresize = () => {
      computeRem()
    }
    // setShowBody(true)
  },[])
   // rem计算
   function computeRem() {
    const htmlEl = document.documentElement;
    var width = screen.width;
    console.log(width)
    // var rem = (width / 750 * 10).toFixed(2);
    // var rem = (width / 375 * 100).toFixed(2);
    var rem = (width / 375 * 10).toFixed(2);
    // 设置 html 的 font-size
    document.documentElement.style.fontSize = rem+ 'px';
    setShowBody(true)
  }
  return <>
          <Head>
              <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0"/>
              <title>向商户付款</title>
          </Head>
          {showBody && <Component {...pageProps} />} 
        </>
 
  
}
  